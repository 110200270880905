var CookieConsent = function () {
    if (this.getCookie("cc_show") != "no") {
        this.openNotification();
    }

    var self = this;

    jQuery("#cookie-consent .cc-option").each(function () {
        if (self.getCookie("cc_" + jQuery(this).data("value")) == "no") {
            jQuery(this).prop("checked", false);
        }
    });

    jQuery("#cookie-consent .cc-toggle-settings").on("click", function () {
        self.toggleSettings();
        return false;
    });

    jQuery("#cookie-consent .cc-accept").on("click", function () {
        self.accept();
        return false; 
    });

    jQuery("#cookie-consent .cc-save").on("click", function () {
        self.save();
        return false;
    });
    jQuery(".cookie-consent-open-settings").on("click", function () {
        self.openNotificationWithSettings();
        return false;
    });

    jQuery(".cc-close").on("click", function () {
        self.closeNotificationWithSettings();
        return false;
    });
};

CookieConsent.prototype = {
    _cc: jQuery("#cookie-consent"),
    accept: function () {
        jQuery("#cookie-consent .cc-option").prop("checked", true);
        this.save();
    },
    save: function () {
        var self = this;

        this.setCookie("cc_show", "no");

        jQuery("#cookie-consent .cc-option").each(function () {
            self.setCookie("cc_" + jQuery(this).data('value'), jQuery(this).is(':checked') ? "yes" : "no");
        });

        this.hideNotification();
    },
    _settingsOpen: false,
    toggleSettings: function () {
        if (this._settingsOpen) {
            this.closeSettings(true);
        } else {
            this.openSettings(true);
        }
    },
    openSettings: function (animate) {
        this._settingsOpen = true;
        jQuery("#cookie-consent .cc-actions-toggle").addClass("cc-closed");
        jQuery("#cookie-consent .cc-settings-toggle").removeClass("cc-closed");

        if (animate) {
            jQuery(".cc-settings-window").slideDown("fast");
        } else {
            jQuery(".cc-settings-window").show();
        }
    },
    closeSettings: function (animate) {
        this._settingsOpen = false;
        jQuery("#cookie-consent .cc-actions-toggle").removeClass("cc-closed");
        jQuery("#cookie-consent .cc-settings-toggle").addClass("cc-closed");

        if (animate) {
            jQuery(".cc-settings-window").slideUp("fast");
        } else {
            jQuery(".cc-settings-window").hide();
        }
    },
    openNotification: function () {
        this._cc.show();
        this._cc.css({
            bottom: -1 * this._cc.outerHeight() + "px"
        }).animate({
            bottom: "0px"
        }, "fast", "linear");
    },
    openNotificationWithSettings: function () {
        this.openSettings(false);

        this.openNotification();
    },
    closeNotificationWithSettings: function () {
        this.closeSettings(false);

        this.hideNotification();
    },
    hideNotification: function () {
        var self = this;
        this._cc.animate({
            bottom: -1 * this._cc.outerHeight() + "px"
        }, "fast", "linear", function () {
            self._cc.hide();
        });
    },
    getCookie: function (cname) {
        return lyteeGetCookie(cname);
    },
    setCookie: function (cname, value) {
        var d = new Date();
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + value + ";" + expires + ";path=/";
    },
};

new CookieConsent();